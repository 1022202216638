import { Link } from "gatsby";
import React from "react";
import BlogPreviewCard from "./blog-grid-card";

// import * as styles from "./project-preview-grid.module.css";
import './blog-preview.css';
function BlogsGrid(props) {
  return (
    <div className="blog-preview-grid">
        
        {props.nodes && props.nodes.map((node)=> {
        //   console.log(item.frontmatter.headerImage);
        //   console.log(item.frontmatter.headerImage!=null?item.frontmatter.headerImage["publicURL"]:"");
          return <BlogPreviewCard {...node}/>
})}

      </div>
  );
}

BlogsGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogsGrid;
